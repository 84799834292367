import { MapButton } from '@ljagis/react-mapping';
import { Box, Tooltip } from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import React from 'react';

interface FullScreenButtonProps {
  active: boolean;
}

const FullScreenButton: React.FC<FullScreenButtonProps> = ({ active }) => {
  return (
    <React.Fragment>
      <Tooltip title="Full Screen">
        <Box>
          <MapButton style={{ padding: 0 }}>
            {active ? (
              <FullscreenExitIcon color="action" />
            ) : (
              <FullscreenIcon color="action" />
            )}
          </MapButton>
        </Box>
      </Tooltip>
    </React.Fragment>
  );
};

export default FullScreenButton;
