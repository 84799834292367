import {
  AppBar,
  AppBarProps,
  Box,
  Container,
  Link,
  Toolbar,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

const footerHeightOnAWBDSite = 340;

interface FooterProps extends Pick<AppBarProps, 'color'> {
  minHeight?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  appBar: {
    background: '#242E3F', // TODO Temporary. This should be incorporated in the theme
    zIndex: 100
  }
}));

const Footer: React.FC<FooterProps> = () => {
  const classes = useStyles();

  return (
    <AppBar position="relative" className={classes.appBar}>
      <Toolbar>
        <Container
          component="main"
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: footerHeightOnAWBDSite
          }}
        >
          <Box flex="1" />
          <Box flex="1 1 0" justifyContent="space-between">
            <Box style={{ padding: '1rem 0' }}>
              <Typography variant="caption" display="block">
                Association of Water Board Directors – Texas
              </Typography>
              <Typography variant="caption" display="block">
                11700 Katy Freeway, Suite 450
              </Typography>
              <Typography variant="caption" display="block">
                Houston, Texas 77079
              </Typography>
            </Box>
            <Box my="20px">
              <Typography variant="body2" align="center" display="block">
                {'Copyright © '}
                <Link color="inherit" href="https://awbd-tx.org">
                  {new Date().getFullYear()} Association of Water Board
                  Directors - Texas
                </Link>
              </Typography>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
