import qs from 'qs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useHistoryPersist = <T extends Record<string, any>>(): [
  T,
  Dispatch<SetStateAction<T>>
] => {
  const { pathname, search } = useLocation();
  const { url: matchUrl } = useRouteMatch();

  const { replace } = useHistory();

  const [params, setParams] = useState<T>(
    () => qs.parse(search, { ignoreQueryPrefix: true }) as T
  );

  useEffect(() => {
    // Do not replace if the page with the hook is not the current active route
    // Page may be loaded in the background
    if (pathname !== matchUrl) return;

    const paramString = qs.stringify(params, { addQueryPrefix: true });

    // Don't unneccesarily replace if params have not changed
    if (paramString === search) return;

    replace({ pathname, search: paramString });
  }, [matchUrl, params, pathname, replace, search]);

  return [params, setParams];
};

export default useHistoryPersist;
