import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: {
    marginTop: theme.spacing(6)
  },
  title: {
    lineHeight: 2.5,
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.up('sm')]: {
      width: '60%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '90%'
    }
  },
  dist: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: 300
    },
    [theme.breakpoints.down('xs')]: {
      height: 110
    }
  },
  map: {
    width: '100%',
    height: 300
  }
}));

const DistrictSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Box className={classes.root} margin={2}>
        <Typography variant="h2" className={classes.title}>
          <Skeleton animation="wave" />
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Skeleton variant="rect" className={classes.dist} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rect" className={classes.map} />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DistrictSkeleton;
