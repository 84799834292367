import { MapButton, useMapStyle } from '@ljagis/react-mapping';
import { Box, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';

import satelliteImg from './satellite.png';
import streetsImg from './streets.png';

const SatelliteButton: React.FC = () => {
  const { setMapStyleFromURL } = useMapStyle();
  const [isStreet, setIsStreet] = useState(true);
  const {
    REACT_APP_DEFAULT_MAP_STYLE_STREETS: streets,
    REACT_APP_DEFAULT_MAP_STYLE_SATELLITE: satellite
  } = process.env;

  const toggleMap = () => {
    setMapStyleFromURL(isStreet ? (satellite as string) : (streets as string));
    setIsStreet(!isStreet);
  };
  return (
    <Tooltip title={isStreet ? 'Satellite Map Style' : 'Streets Map Style'}>
      <Box>
        <MapButton onClick={toggleMap} style={{ padding: 0 }}>
          <img
            src={isStreet ? satelliteImg : streetsImg}
            alt=""
            height={30}
            width={30}
          />
        </MapButton>
      </Box>
    </Tooltip>
  );
};

export default SatelliteButton;
