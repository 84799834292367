import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { MapProvider, MapboxProvider } from '@ljagis/react-mapping';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const { REACT_APP_MAPBOX_ACCESS_TOKEN: accessToken } = process.env;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <MapboxProvider accessToken={accessToken as string}>
        <MapProvider>
          <CssBaseline />
          <App />
        </MapProvider>
      </MapboxProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
