import {
  Avatar,
  Container,
  CssBaseline,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';

import SearchList from '../components/SearchList';
import { useHistoryPersist } from '../hooks/useHistoryPersist';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  container: { marginTop: theme.spacing(14), marginBottom: theme.spacing(14) },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

const Search: React.FC = () => {
  const classes = useStyles();
  const [results, setResults] = useState({
    topMatches: [],
    term: '',
    hasError: false
  });
  const [params, setParams] = useHistoryPersist<{ q?: string }>();
  const [searchTerm, setSearchTerm] = useState<
    NonNullable<{
      term: string;
      /** Should search occur immediately (without debounce) */
      immediate?: boolean;
    }>
  >({ term: params?.q || '', immediate: true });

  useEffect(() => {
    setParams({ q: searchTerm.term.length ? searchTerm.term : '' });
  }, [searchTerm, setParams]);

  useEffect(() => {
    const axiosCancel = axios.CancelToken.source();

    const searchHandler = debounce(
      (text) => {
        axios
          .get(
            `https://c0irz37w2e.execute-api.us-east-1.amazonaws.com/prod/search?t=${text}`,
            {
              cancelToken: axiosCancel.token,
              headers: {
                'x-api-key': '5tXeBEFfrv9zAIa5rtdof1XSSSqktiuU3NukqxIW'
              }
            }
          )
          .then((result) => {
            setResults({
              topMatches: result.data.topMatches,
              term: text,
              hasError: false
            });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              // Request superseded by new request and cancelled
              return;
            }
            setResults({ topMatches: [], term: text, hasError: true });
          });
      },
      searchTerm.immediate ? 0 : 500
    );

    searchHandler(searchTerm.term);

    return () => {
      axiosCancel.cancel();
    };
  }, [searchTerm]);

  return (
    <div>
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LocationOnIcon />
          </Avatar>
          <Typography component="h3" variant="h1">
            Find my District
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={(e) => e.preventDefault()}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="search"
              name="search"
              placeholder="Search by Address or District Name"
              autoFocus
              value={searchTerm.term}
              onChange={(e) => {
                setSearchTerm({ term: e.target.value });
              }}
            />
          </form>
        </div>

        {results.hasError ? (
          <Alert severity="error">
            <Typography variant="body2">
              There was an issue with your search.
            </Typography>
            <Typography variant="body2">
              Please contact us if the problem continues.
            </Typography>
          </Alert>
        ) : (
          <SearchList matches={results.topMatches} searchTerm={results.term} />
        )}
      </Container>
    </div>
  );
};

export default Search;
