import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  MenuItem,
  MenuList,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  accordion: { backgroundColor: '#FFFFFF', padding: '.5rem' },
  innerAccordion: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      backgroundColor: 'white'
    }
  },
  menu: {
    padding: '13px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  item: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: '10px 25px'
  },
  paper: {
    backgroundColor: '#FFFFFF'
  }
}));

const MobileMenuAbout: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<null | HTMLLIElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box onMouseEnter={handleToggle} onMouseLeave={() => setOpen(false)}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h6" color="primary">
            ABOUT
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuList>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/about/board-of-trustees/">
                Board of Trustees
              </Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/about/advisory-council/">
                Advisory Council
              </Link>
            </MenuItem>
            <Accordion className={classes.innerAccordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon color="primary" />}
                style={{ padding: '10px 25px' }}
              >
                <Typography variant="h6" color="primary">
                  Committees
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <MenuList>
                  <MenuItem className={classes.item}>
                    <Link href="http://awbd.org/about/committees/government-affairs/">
                      Government Affairs
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.item}>
                    <Link href="http://awbd.org/about/committees/programs-education-committee/">
                      Programs &amp; Education
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.item}>
                    <Link href="http://awbd.org/about/committees/nominating-committee/">
                      Nominating
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.item}>
                    <Link href="http://awbd.org/about/committees/strategic-planning-committee/">
                      Strategic Planning
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.item}>
                    <Link href="http://awbd.org/about/committees/membership-committee/">
                      Membership
                    </Link>
                  </MenuItem>
                </MenuList>
              </AccordionDetails>
            </Accordion>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/about/staff/">Staff</Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/about/history/">History</Link>
            </MenuItem>
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MobileMenuAbout;
