import { Box } from '@material-ui/core';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Member from '../pages/Member';
import Public from '../pages/Public';
import Search from '../pages/Search';
import Footer from './Footer';
import Header from './Header';
import ScrollToTop from './ScrollToTop';

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Box height="100vh" display="flex" flexDirection="column">
        <Header />
        <Box flex="1" marginTop={10} marginBottom={10}>
          <Switch>
            <Route exact path="/" component={Search} />
            <Route exact path="/public/:id" component={Public} />
            <Route exact path="/member/:id" component={Member} />
          </Switch>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
};

export default App;
