import { Box, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

interface ContactItemProps {
  contact: {
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    };
    firstName: string;
    jobTitle: string;
    lastName: string;
    organization: string;
    phone: string;
  };
  isOperator: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#E0E6EF',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: '300px'
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  },
  title: {
    opacity: '50%',
    [theme.breakpoints.down('xs')]: {
      marginTop: '.5rem'
    }
  }
}));

const ContactItem: React.FC<ContactItemProps> = ({ contact, isOperator }) => {
  const classes = useStyles();
  const title = isOperator ? 'Operator' : 'Attorney';
  const fullName = [contact?.firstName, contact?.lastName]
    .filter((v) => v)
    .join(' ');

  return (
    <Grid item xs={12} sm={4}>
      <Paper className={classes.root}>
        {fullName.length > 0 && (
          <Typography
            variant="h5"
            color="primary"
            align="left"
            style={{ margin: '0 0 1rem 0' }}
          >
            {fullName}
          </Typography>
        )}
        <Typography
          variant="h5"
          color="secondary"
          align="left"
          style={{ margin: '0 0 1rem 0' }}
        >
          {contact.organization}
        </Typography>
        <Typography variant="subtitle1" color="primary" align="left">
          {contact?.address?.address1}
        </Typography>
        <Typography variant="subtitle1" color="primary" align="left">
          {contact?.address?.address2}
        </Typography>
        <Typography variant="subtitle1" color="primary" align="left">
          {`${contact?.address?.city || ''} ${contact?.address?.state || ''} ${
            contact?.address?.zip || ''
          }`}
        </Typography>
        <Typography variant="subtitle1" color="primary" align="left">
          {contact?.phone}
        </Typography>
        <Box flex="1" />
        <Box mt={2}>
          <Typography
            variant="caption"
            color="primary"
            align="left"
            className={classes.title}
          >
            {title}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default ContactItem;
