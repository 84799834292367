import {
  AppBar,
  Box,
  ClickAwayListener,
  Container,
  Grow,
  Hidden,
  Link,
  MenuList,
  Paper,
  Popper,
  makeStyles
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import * as React from 'react';

import MenuAbout from './menuItems/MenuAbout';
import MenuConnect from './menuItems/MenuConnect';
import MenuEvents from './menuItems/MenuEvents';
import MenuInvolved from './menuItems/MenuInvolved';
import MenuLearn from './menuItems/MenuLearn';
import MenuMembersLogin from './menuItems/MenuMembersLogin';
import MobileMenuAbout from './mobileMenuItems/MobileMenuAbout';
import MobileMenuConnect from './mobileMenuItems/MobileMenuConnect';
import MobileMenuEvents from './mobileMenuItems/MobileMenuEvents';
import MobileMenuInvolved from './mobileMenuItems/MobileMenuInvolved';
import MobileMenuLearn from './mobileMenuItems/MobileMenuLearn';
import MobileMenuMembersLogin from './mobileMenuItems/MobileMenuMembersLogin';

const useStyles = makeStyles((theme) => ({
  menu: {
    color: 'white',
    padding: '13px 20px'
  },
  item: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    fontSize: '.8em',
    color: theme.palette.primary.main,
    padding: '10px 25px'
  },
  paper: {
    backgroundColor: '#FFFFFF',
    width: '100vw',
    marginRight: '-5px',
    marginTop: '1.5rem'
  }
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  return (
    <AppBar color="primary" elevation={0}>
      <Container
        component="main"
        style={{
          minHeight: 75,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Link href="https://awbd.org/" style={{ display: 'inline-block' }}>
          <img
            src="https://awbd.org/wp-content/uploads/2021/04/AWBD-Main-logo_White_Blue2.png"
            alt="awbd logo"
            style={{ display: 'block', maxWidth: '60px' }}
          />
        </Link>
        <Hidden only={['xs', 'sm', 'md']}>
          <Box
            display="flex"
            flexDirection="row"
            // style={{ marginRight: '-10rem' }}
          >
            <MenuAbout />
            <MenuLearn />
            <MenuEvents />
            <MenuInvolved />
            <MenuConnect />
            <MenuMembersLogin />
          </Box>
        </Hidden>
        <Hidden only={['lg', 'xl']}>
          <MenuIcon
            ref={anchorRef}
            onClick={handleToggle}
            style={{ height: '22px', width: '22px', cursor: 'pointer' }}
          />
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper className={classes.paper} square>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      style={{ textTransform: 'uppercase', padding: 0 }}
                    >
                      <MobileMenuAbout />
                      <MobileMenuLearn />
                      <MobileMenuEvents />
                      <MobileMenuInvolved />
                      <MobileMenuConnect />
                      <MobileMenuMembersLogin />
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Hidden>
      </Container>
    </AppBar>
  );
};

export default Header;
