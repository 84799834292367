import {
  Box,
  ClickAwayListener,
  Grow,
  Link,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  menu: {
    color: 'white',
    padding: '13px 20px'
  },
  item: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    fontSize: '.8em',
    color: theme.palette.primary.main,
    padding: '10px 25px'
  },
  paper: {
    backgroundColor: '#FFFFFF'
  }
}));

const MenuLearn: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<null | HTMLLIElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box onMouseEnter={handleToggle} onMouseLeave={() => setOpen(false)}>
      <MenuItem className={classes.menu} ref={anchorRef}>
        <Typography variant="h6">LEARN</Typography>
        <ListItemIcon style={{ minWidth: 'auto', marginLeft: '.5rem' }}>
          <ExpandMoreIcon fontSize="small" style={{ color: 'white' }} />
        </ListItemIcon>
      </MenuItem>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper className={classes.paper} square>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  style={{ textTransform: 'uppercase' }}
                >
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/what-is-a-water-district/">
                      What is a Water District?
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/awbd-on-demand/">
                      AWBD on Demand
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/news-journal/">
                      News &amp; Journal
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/interactive-maps/">
                      Interactive Maps
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/laws-regulations/">
                      Laws &amp; Regulations
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/resources/">
                      Resources
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleClose} className={classes.item}>
                    <Link href="http://awbd.org/learn/frequently-asked-quesitons/">
                      FAQs
                    </Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default MenuLearn;
