import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  MenuItem,
  MenuList,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  accordion: { backgroundColor: '#FFFFFF', padding: '.5rem' },
  menu: {
    padding: '13px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  item: {
    fontFamily: 'Roboto Condensed',
    fontWeight: 600,
    color: theme.palette.primary.main,
    padding: '10px 25px'
  },
  paper: {
    backgroundColor: '#FFFFFF'
  }
}));

const MobileMenuMembersLogin: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<null | HTMLLIElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box onMouseEnter={handleToggle} onMouseLeave={() => setOpen(false)}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <Typography variant="h6" color="primary">
            MEMBERS LOGIN
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MenuList>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/connect/directory/">
                Members Info
              </Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/connect/ads-sponsorship/">
                My Account
              </Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/contact-us/">AWBD Docs</Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/get-involved/submit-ideas/">
                AWBD Videos
              </Link>
            </MenuItem>
            <MenuItem className={classes.item}>
              <Link href="http://awbd.org/get-involved/submit-ideas/">
                Trustees
              </Link>
            </MenuItem>
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default MobileMenuMembersLogin;
