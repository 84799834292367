import {
  Box,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Typography
} from '@material-ui/core';
import * as React from 'react';

import SearchItem from './SearchItem';

interface SearchListProps {
  matches: {
    districtId: string;
    districtName: string;
    formattedAddress: string;
    kind: string;
  }[];
  searchTerm: string;
}

const SearchList: React.FC<SearchListProps> = ({ matches, searchTerm }) => {
  if (!matches.length && searchTerm.length) {
    return (
      <Box mt={4} mx={4}>
        No matches for the search &quot;{searchTerm}&quot;
      </Box>
    );
  }

  const groupedMatches = matches.reduce(
    (total, item) => ({
      ...total,
      [item.formattedAddress || 'district']: [
        ...(total[item.formattedAddress || 'district'] || []),
        item
      ]
    }),
    {} as Record<
      string,
      {
        districtId: string;
        districtName: string;
        formattedAddress: string;
        kind: string;
      }[]
    >
  );

  const keys = Object.keys(groupedMatches).filter(
    (item) => item !== 'district'
  );

  return (
    <Box>
      {Object.keys(groupedMatches).map((key) => {
        if (!groupedMatches[key][0]?.districtId) {
          return (
            <Box key={key} py={2} margin={2}>
              <Paper style={{ backgroundColor: '#E0E6EF' }}>
                <List style={{ padding: '0' }}>
                  <ListItem divider>
                    <Typography
                      variant="h4"
                      style={{ margin: '.5rem 0', fontWeight: 500 }}
                    >
                      {key}
                    </Typography>
                  </ListItem>
                  <ListItem divider>
                    <ListSubheader disableGutters style={{ lineHeight: 2 }}>
                      No districts at this address
                    </ListSubheader>
                  </ListItem>
                </List>
              </Paper>
            </Box>
          );
        }

        return (
          <React.Fragment key={key}>
            {key === 'district' && (
              <Box mt={4} mb={2} mx={4}>
                {`${groupedMatches?.district && groupedMatches?.district.length}
                districts with names matching the search "${searchTerm}"`}
              </Box>
            )}
            {key === keys[0] && (
              <Box mt={4} mb={2} mx={4}>
                {`${
                  Object.keys(groupedMatches).filter(
                    (item) => item !== 'district'
                  ).length
                }
                address${
                  Object.keys(groupedMatches).filter(
                    (item) => item !== 'district'
                  ).length <= 1
                    ? ''
                    : 'es'
                } matching the search "${searchTerm}"`}
              </Box>
            )}

            <Box py={2} margin={2}>
              <Paper style={{ backgroundColor: '#E0E6EF' }}>
                <List style={{ padding: '0' }}>
                  {key !== 'district' && (
                    <ListItem divider>
                      <Typography
                        variant="h4"
                        style={{ margin: '.5rem 0', fontWeight: 500 }}
                      >
                        {key}
                      </Typography>
                    </ListItem>
                  )}

                  {key !== 'district' && (
                    <ListItem divider>
                      <ListSubheader disableGutters style={{ lineHeight: 2 }}>
                        {`${groupedMatches[key].length} district${
                          groupedMatches[key].length > 1 ? 's' : ''
                        } at this address`}
                      </ListSubheader>
                    </ListItem>
                  )}
                  {groupedMatches[key].map((match) => (
                    <SearchItem key={key + match?.districtId} data={match} />
                  ))}
                </List>
              </Paper>
            </Box>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default SearchList;
