import { createMuiTheme } from '@material-ui/core';
import { green, grey, red, yellow } from '@material-ui/core/colors';
import createSpacing from '@material-ui/core/styles/createSpacing';

const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16
        },
        body: {
          'overscroll-behavior-y': 'none',
          fontSize: '1rem'
        }
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover, &:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiContainer: {
      root: {
        paddingLeft: '10px !important', // Match AWBD site
        paddingRight: '10px !important'
      }
    },
    MuiListItemIcon: {
      root: { minWidth: 48 }
    },
    MuiTooltip: {
      tooltip: {
        padding: createSpacing(6)(1, 1.5),
        fontSize: `${12 / 16}rem`
      }
    }
    // MuiPaper: {
    //   root: {
    //     backgroundColor: '#E0E6EF'
    //   }
    // }
  },
  props: {
    MuiButton: {
      disableElevation: true
    },
    MuiContainer: {
      maxWidth: 'lg'
    },
    MuiLink: {
      underline: 'none'
    },
    MuiMenu: {
      elevation: 2
    },
    MuiSvgIcon: {
      fontSize: 'small'
    },
    MuiTooltip: {
      enterDelay: 700
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1140, // AWBD site uses 1140 max-width for containers
      xl: 1920
    }
  },
  palette: {
    primary: {
      main: '#223360'
    },
    secondary: {
      main: 'rgb(182, 36, 42)'
    },
    text: {
      secondary: grey['500']
    },
    success: {
      light: green['300'],
      main: green['700'],
      dark: green['800'],
      contrastText: '#FFFFFF'
    },
    info: {
      light: yellow['300'],
      main: yellow['700'],
      dark: yellow['900'],
      contrastText: '#FFFFFF'
    },
    error: {
      light: red['300'],
      main: red['700'],
      dark: red['900'],
      contrastText: '#FFFFFF'
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    fontFamily: '"Jost", Sans-serif',
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: `${26 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.2
    },
    h2: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1
    },
    h3: {
      fontSize: '1.8rem',
      fontWeight: 600,
      lineHeight: 1.3
    },
    h4: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.33
    },
    h5: {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: 1.6
    },
    h6: {
      fontSize: `1rem`,
      fontWeight: 700,
      fontFamily: 'Roboto Condensed',
      lineHeight: '20px',
      letterSpacing: '0.5px'
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.6
    },
    subtitle2: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.6
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.538461538
    },
    body2: {
      fontSize: `${13 / 16}rem`,
      lineHeight: 1.538461538
    },
    button: {
      fontSize: `${13 / 16}rem`,
      lineHeight: 1.538461538
    },
    caption: {
      fontSize: `.8rem`,
      fontFamily: 'Roboto',
      lineHeight: 1.8
    }
  }
});
export default theme;
