import { Box, Link, Paper, Typography, makeStyles } from '@material-ui/core';
import * as React from 'react';

interface DistrictGeneralProps {
  district: {
    address: {
      address1: string;
      address2: string;
      city: string;
      state: string;
      zip: string;
    };
    attorneys: {
      address: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
      };
      firstName: string;
      jobTitle: string;
      lastName: string;
      organization: string;
      phone: string;
    }[];
    county: string;
    name: string;
    operators: {
      address: {
        address1: string;
        address2: string;
        city: string;
        state: string;
        zip: string;
      };
      firstName: string;
      jobTitle: string;
      lastName: string;
      organization: string;
      phone: string;
    }[];
    phone: string;
    website: string;
    isMember: boolean;
  };
}

const useStyles = makeStyles((theme) => ({
  districtGen: {
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: '#E0E6EF',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  }
}));

const DistrictGeneral: React.FC<DistrictGeneralProps> = ({ district }) => {
  const classes = useStyles();

  if (!district?.name && !district?.address) {
    return (
      <Paper className={classes.districtGen}>
        <Typography variant="subtitle1" color="primary" align="left">
          No available district information
        </Typography>
      </Paper>
    );
  }
  return (
    <Paper className={classes.districtGen}>
      <Typography
        variant="h5"
        color="secondary"
        align="left"
        style={{ margin: '0 0 .5rem 0' }}
      >
        {district && `${district?.county} County`}
      </Typography>
      <Typography variant="subtitle1" color="primary" align="left">
        {district?.address?.address1}
      </Typography>
      <Typography variant="subtitle1" color="primary" align="left">
        {district?.address?.city} {district?.address?.state}
      </Typography>
      <Typography variant="subtitle1" color="primary" align="left">
        {district?.address?.zip}
      </Typography>
      <Typography variant="subtitle1" color="primary" align="left">
        {district?.phone}
      </Typography>
      {district?.website && (
        <Link href={district?.website}>{district?.website}</Link>
      )}
      <Box flex="1" />
      <Box mt={2}>
        <Typography
          variant="caption"
          color="primary"
          align="left"
          style={{
            opacity: '50%'
          }}
        >
          {district?.isMember
            ? 'AWBD Member - Data Source: AWBD'
            : 'Not an AWBD Member - Data Source: TCEQ'}
        </Typography>
      </Box>
    </Paper>
  );
};

export default DistrictGeneral;
