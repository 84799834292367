import {
  AppBarProps,
  Box,
  Button,
  ListItem,
  ListItemText
} from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface SearchListProps extends Pick<AppBarProps, 'color'> {
  data: {
    districtId: string;
    districtName: string;
    formattedAddress?: string;
    kind: string;
  };
}

const SearchItem: React.FC<SearchListProps> = ({ data }) => {
  return (
    <ListItem divider>
      <ListItemText
        primary={data?.districtName}
        style={{ fontSize: '1rem', color: 'black' }}
      />
      <Box ml={2}>
        <Link
          to={`/public/${data?.districtId}`}
          style={{ textDecoration: 'none' }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ whiteSpace: 'nowrap' }}
          >
            Learn More
          </Button>
        </Link>
      </Box>
    </ListItem>
  );
};

export default SearchItem;
