import {
  LayerVisibility,
  Map,
  MapButton,
  MapOverlay,
  ZoomInButton,
  ZoomOutButton,
  useGeoJSONLayer
} from '@ljagis/react-mapping';
import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import LayersIcon from '@material-ui/icons/Layers';
import axios from 'axios';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import ContactList from '../components/District/ContactItem';
import DistrictGeneral from '../components/District/DistrictGeneral';
import DistrictSkeleton from '../components/District/DistrictSkeleton';
import FullScreenButton from '../components/MapOverlay/FullScreenButton';
import SatelliteButton from '../components/MapOverlay/SatelliteButton';
import { useHistoryPersist } from '../hooks/useHistoryPersist';
import { districtResult } from '../types/SearchTypes';

const DRAWER_WIDTH = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  grid: {
    height: '300px'
  },
  paper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mapRel: {
    position: 'relative',
    height: '100%',
    zIndex: 1
  },
  map: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0
  },
  mapShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: DRAWER_WIDTH
  },
  poc: {
    marginTop: theme.spacing(6),
    width: '100%'
  },
  drawer: {
    marginLeft: 'auto',
    width: DRAWER_WIDTH,
    '& .MuiBackdrop-root': {
      display: 'none'
    },
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,
      position: 'absolute',
      height: '100%',
      transition: 'none !important'
    }
  },
  fullScreen: {
    height: '100%'
  }
}));

const DistrictPublic: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const handle = useFullScreenHandle();
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [params] = useHistoryPersist<{
    l?: string;
  }>();
  const [data, setData] = useState<districtResult | null>(null);
  // const isMapSupported = useMapSupported();

  const { setData: setGeoJSON } = useGeoJSONLayer({
    layers: [
      {
        type: 'fill',
        paint: { 'fill-color': 'rgba(92, 205, 222, 0.3)' }
      },
      {
        type: 'line',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'rgba(53, 120, 138, 1)',
          'line-width': 1.5,
          'line-opacity': {
            stops: [
              [6, 0],
              [12, 0.8]
            ]
          }
        }
      }
    ]
  });

  useEffect(() => {
    axios
      .get(
        `https://c0irz37w2e.execute-api.us-east-1.amazonaws.com/prod/public/districts/${id}`,
        {
          headers: {
            'x-api-key': '5tXeBEFfrv9zAIa5rtdof1XSSSqktiuU3NukqxIW'
          }
        }
      )
      .then((result) => {
        setData(result.data);
        setGeoJSON(result.data.district.geometry);
      });
  }, [id, setGeoJSON]);

  useEffect(() => {
    if (params?.l === '1') {
      setSidebarOpen(true);
    }
  }, [params]);

  const classes = useStyles();

  if (!data) {
    return <DistrictSkeleton />;
  }

  return (
    <div>
      <Container component="main">
        <Box margin={2}>
          <CssBaseline />
          <div className={classes.paper}>
            <Box flex="1 1 0" alignItems="start">
              <Typography variant="h2" color="primary">
                {data?.district?.name}
              </Typography>
              <Box mt={2.5}>
                <Divider />
              </Box>
            </Box>
            <Box mt={4} width={1}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={4} className={classes.grid}>
                  <Box flexDirection="row" flex="1 1 0%" height="100%">
                    {data && <DistrictGeneral district={data.district} />}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={8} style={{ height: '300px' }}>
                  <FullScreen handle={handle} className={classes.fullScreen}>
                    {data && (
                      <Paper className={classes.mapRel}>
                        <Box
                          className={clsx(classes.map, {
                            [classes.mapShift]: sidebarOpen
                          })}
                          position="relative"
                          display="flex"
                          flexDirection="row"
                          flex="1 1 0%"
                          height="100%"
                        >
                          <Map
                            style={{ flex: '1 1 0%' }}
                            // initialMapStyle={`${process.env.PUBLIC_URL}/streets.json`}
                            initialMapStyle={
                              params?.l && params?.l === '1'
                                ? `${process.env.PUBLIC_URL}/streets.json`
                                : process.env
                                    .REACT_APP_DEFAULT_MAP_STYLE_STREETS
                            }
                            initialBounds={data.geoBounds}
                            initialBoundsPadding={20}
                            maxZoom={15}
                          />
                          <Box position="absolute" right={0} top={0}>
                            {params?.l === '1' && (
                              <Box m="10px">
                                <MapOverlay>
                                  <Tooltip title="Layers">
                                    <Box>
                                      <MapButton
                                        onClick={() =>
                                          setSidebarOpen(!sidebarOpen)
                                        }
                                        style={{ padding: 0 }}
                                      >
                                        <LayersIcon color="action" />
                                      </MapButton>
                                    </Box>
                                  </Tooltip>
                                </MapOverlay>
                              </Box>
                            )}
                            <Box m="10px">
                              <MapOverlay>
                                <Box>
                                  <ZoomInButton />
                                  <Divider />
                                  <ZoomOutButton />
                                </Box>
                              </MapOverlay>
                            </Box>
                            <Box m="10px">
                              <MapOverlay style={{ overflow: 'hidden' }}>
                                <SatelliteButton />
                              </MapOverlay>
                            </Box>
                          </Box>
                          <Box position="absolute" right={0} bottom={34}>
                            <Box m="10px">
                              <MapOverlay
                                style={{ overflow: 'hidden' }}
                                onClick={
                                  handle.active ? handle.exit : handle.enter
                                }
                              >
                                <FullScreenButton active={handle.active} />
                              </MapOverlay>
                            </Box>
                          </Box>
                        </Box>
                        <Drawer
                          open={sidebarOpen}
                          className={classes.drawer}
                          variant="persistent"
                          anchor="right"
                        >
                          <LayerVisibility toggleAllButton={false} />
                        </Drawer>
                      </Paper>
                    )}
                  </FullScreen>
                </Grid>
              </Grid>
            </Box>
            <Box className={classes.poc}>
              <Typography variant="h3" color="primary">
                Contacts
              </Typography>
              <Box mt={2}>
                <Divider />
              </Box>
              <Box mt={4} width={1}>
                <Grid container spacing={3}>
                  {data?.district?.operators?.map(
                    (item: {
                      address: {
                        address1: string;
                        address2: string;
                        city: string;
                        state: string;
                        zip: string;
                      };
                      firstName: string;
                      jobTitle: string;
                      lastName: string;
                      organization: string;
                      phone: string;
                    }) => (
                      <ContactList contact={item} key={uuidv4()} isOperator />
                    )
                  )}
                  {data?.district?.attorneys?.map(
                    (item: {
                      address: {
                        address1: string;
                        address2: string;
                        city: string;
                        state: string;
                        zip: string;
                      };
                      firstName: string;
                      jobTitle: string;
                      lastName: string;
                      organization: string;
                      phone: string;
                    }) => (
                      <ContactList
                        contact={item}
                        key={uuidv4()}
                        isOperator={false}
                      />
                    )
                  )}
                </Grid>
              </Box>
              {data?.district?.operators.length < 1 &&
                data?.district?.attorneys.length < 1 && (
                  <Box mt={2}>
                    <Typography variant="subtitle1">No contacts</Typography>
                  </Box>
                )}
            </Box>
          </div>
        </Box>
      </Container>
    </div>
  );
};

export default DistrictPublic;
